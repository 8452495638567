import * as React from 'react';
import { USER_OS } from './constants';
export * from './reactQueryDefaultConfigs';
export * from './apiCalls';
export * from './hooks/useQueryFilters';
export * from './string';
export * from './constants';

export const getLocalStorageData = (key: string, isToken: boolean = false) => {
  return isToken
    ? localStorage.getItem(key)
    : localStorage.getItem(key)
    ? JSON.parse(localStorage.getItem(key)!)
    : null;
};

export const handleLogout = () => {
  localStorage.clear();
};

export const detectUserOS = (): string => {
  const userAgent = navigator?.userAgent?.toLowerCase();
  if (userAgent.includes('win')) return USER_OS.WIN;
  if (userAgent.includes('mac')) return USER_OS.MAC;
  if (userAgent.includes('linux')) return USER_OS.LINUX;
  if (userAgent.includes('android')) return USER_OS.ANDROID;
  if (userAgent.includes('iphone') || userAgent.includes('ipad')) return USER_OS.IOS;
  return USER_OS.UNKNOWN;
};
